const TermBrowse = {
  props: {
    taxonomy: { default: false }, //taxonomy to query
  },

  data: function () {
    return {
      list: [],
      tree: [],
      level: 0,
    };
  },

  created() {
    this.list = domestiko.sectors;
  },

  methods: {
    back() {
      this.level--;
      this.list = this.tree.pop();
    },
    select: function (item) {
      if (item.hasOwnProperty('children')) {
        this.level++;
        this.tree.push(this.list);
        this.list = item.children;
        return;
      }
      //reset and select
      this.tree = [];
      this.level = 0;
      this.list = domestiko.sectors;
      this.$emit('select', item);
    },
  },

  template: `
    <ul :class="{'term-browse': true, ['level'+level]: true}">
    <li v-if="level > 0">
      <a @mousedown.prevent="back()" class="back">
        Atrás
      </a>
    </li>
    <li v-for="(item, i) in list">
      <a @mousedown.prevent="select(item)">
        {{ item.name }}
      </a>
    </li>
    </ul>
  `,
};
