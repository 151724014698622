(function (el) {
  if (!el) return;

  new Vue({
    data: {
      jid: false,
      status: 'available',
      external: '',
      loading: false,
    },
    computed: {
      user() {
        return domestiko.user;
      },
      domain() {
        return this.external ? new URL(this.external).hostname : null;
      },
    },
    created: function () {
      this.jid = $(el).data('job');
      if (!this.jid) return;
      domestiko.$on('job.applied', () => {
        this.applied = true;
      });
      domestiko.$on('user.loggedin', this.init);
    },
    methods: {
      init() {
        this.loading = true;
        axios
          .get('/empleo/inscripcion/', {
            params: { job: this.jid },
          })
          .then((response) => response.data)
          .then((response) => {
            this.loading = false;
            this.status = response.status;
            this.external = response.external;
          })
          .catch((error) => {
            this.loading = false;
            domestiko.logout({}, location.pathname);
            console.error(error);
          });
      },
      apply() {
        return domestiko.$emit(
          domestiko.user ? 'form.apply' : 'form.login',
          this.jid,
        );
      },
    },
    template: `
      <div class="uk-child-width-1-1" v-if="status !== 'owner'">
          <template v-if="!loading">
            <button
              v-if="status === 'applied'"
              class="uk-button uk-disabled" >
              Ya estás inscrito
            </button>
            <button
              v-if="status === 'available' && !external"
              @click="apply()"
              class="uk-button uk-button-dmtk" >
              Inscribirse ahora
            </button>
            <a
              v-if="status === 'available' && external"
              class="uk-button uk-button-dmtk dmtk-external-job"
              :href="external" target="_blank" rel="nofollow noopener"
              :data-domain="domain"
             >Consultar oferta
            </a>
            <button
                v-if="!status === 'unavailable'"
                class="uk-button uk-disabled" >
              No puedes inscribirte 
            </button>
            <span v-if="status === 'unavailable'" class="uk-text-small">
              Por favor 
              <a v-if="user.profile" href="/usuarios/panel/#/perfil/" class="underlined">completa tu perfil</a> 
              <a v-else href="/usuarios/panel/?signup=true" class="underlined">crea un perfil</a> 
              para inscribirte a esta oferta.
            </span>
          </template>
          <button
              v-if="loading"
              class="uk-button uk-disabled uk-display-block" >
            comprobando...
          </button>
      </div>
	`,
  }).$mount(el);
})(document.getElementById('jobs-apply-button'));
