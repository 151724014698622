(function (el) {
  if (!el) return;

  new Vue({
    data: function () {
      return {
        loading: false,
        profile: {},
        data: {
          user: '',
          email: '',
          username: '',
          message: '',
          tos: false,
        },
      };
    },
    computed: {
      user() {
        return domestiko.user;
      },
      valid() {
        return (
          (domestiko.user ||
            (this.data.username && this.data.email && this.data.tos)) &&
          !!this.data.message
        );
      },
    },
    created() {
      domestiko.$on('form.contact', this.open);
    },
    methods: {
      open(profile) {
        if (
          domestiko.user.profile &&
          domestiko.user.profile === profile.profile
        ) {
          UIkit.modal.alert(
            'Lo sentimos, pero no puedes enviarte mensajes a ti mismo',
          );
          return;
        }
        this.profile = profile;
        UIkit.modal('#profile-contact-modal').show();
      },
      contact() {
        UIkit.modal('#profile-contact-modal').hide();
        UIkit.modal('#profile-contact-form').show();
      },
      publish() {
        if (this.user) {
          this.data.tos = true;
          this.data.user = this.user._id;
        }
        const contact = { ...this.data, profile: this.profile.profile };
        axios
          .post('/directorio/contacto/', { contact })
          .then((response) => response.data)
          .then((response) => {
            UIkit.modal('#profile-contact-form').hide();
            customAlert(
              '¡Enhorabuena!',
              this.user
                ? 'Tu mensaje se ha enviado.'
                : 'Tu mensaje se ha enviado, pero para que llegue a su destinatario tienes que validar tu correo electrónico. Recibirás un mensaje en tu cuenta de correo.',
            );
          })
          .catch((error) => {
            customAlert(
              'Se ha producido un error',
              'Por favor revise sus datos y vuelva a intentarlo.',
            );
          })
          .finally(() => {
            this.loading = false;
            this.data.message = '';
          });
      },
    },
    template: `
          <div>
          <div id="profile-contact-modal" uk-modal>
            <div class="uk-modal-dialog uk-margin-auto-vertical">
              <button class="uk-modal-close-default" type="button" uk-close></button>
              <div class="uk-modal-header">
                <h3>Tienes dos formas de contactar con {{profile.name}}</h3>
              </div>
              <div class="uk-modal-body">
                <h4 class="uk-margin-small">Publica un anuncio</h4>
                <p class="uk-margin-small">
                  y así podrás comparar distintos candidatos y/o presupuestos.
                </p>
                <a href="/directorio/pide-presupuesto/" class="uk-button uk-button-dmtk profiles-contact-post">
                  Publica un anuncio gratis
                </a>
                <hr>
                <h4 class="uk-margin-small">Contactar con {{profile.name}}</h4>
                <p class="uk-margin-small">
                  Quiero enviar mi propuesta de trabajo solo a {{profile.name}}
                </p>
                <button @click="contact()" class="uk-button uk-text-uppercase profiles-contact-message">Contactar ahora</button>
              </div>
            </div>
          </div>
          <div id="profile-contact-form" uk-modal>
            <form class="uk-modal-dialog uk-margin-auto-vertical" @submit.prevent="publish()">
              <button class="uk-modal-close-default" type="button" uk-close></button>
              <div class="uk-modal-header">
                <h3>Contacta con {{ profile.name }}</h3>
              </div>
              <div class="uk-modal-body">
                <div class="uk-grid uk-child-width-1-2@s uk-margin-bottom" v-if="!user">
                  <div class="uk-margin-top">
                    <label>Nombre</label>
                    <input type="text" class="uk-input" v-model="data.username" required>
                  </div>
                  <div class="uk-margin-top">
                    <label>Correo electrónico</label>
                    <input type="email" class="uk-input" v-model="data.email" required>
                  </div>
                </div>
                <div>
                  <label>Mensaje:</label>
                  <textarea class="uk-textarea" rows="5" v-model="data.message"></textarea>
                </div>
                <template v-if="!user">
                  <div class="uk-margin-top">
                    <p class="legal -tos">
                      Responsable del Tratamiento: Luis G. Valdivieso; finalidad del tratamiento: prestación del
                      servicio; legitimación del tratamiento: consentimiento del interesado; destinatarios: otros
                      usuarios del sitio web, colaboradores en la prestación del servicio y autoridades cuando se nos
                      requiera legalmente; derechos: acceder, rectificar y suprimir los datos, así como otros derechos,
                      como se explica en la información adicional.
                      Derechos: correo a <a href="mailto:privacidad@domestiko.com">privacidad@domestiko.com</a>.
                      Más información en la <a href="/info/politica-de-privacidad/" target="_blank">política de privacidad</a> y <a href="/info/politica-de-cookies/" target="_blank">cookies</a>.
                    </p>
                    <div class="uk-margin-top">
                      <input type="checkbox" class="uk-checkbox" v-model="data.tos"/>
                      He leído y acepto las
                      <a href="/info/nota-legal/" target="_blank">Condiciones de servicio</a>
                      y la
                      <a href="/info/politica-de-privacidad/" target="_blank">Política de Privacidad de Doméstiko</a>.
                    </div>
                  </div>
                </template>
              </div>
              <div class="uk-modal-footer uk-text-right">
                <button
	                type="submit"
                    :disabled="loading || !valid"
                    class="uk-button uk-button-dmtk profiles-contact-message-send"
                    :class="{'uk-button-secondary': !valid}"
                >{{ loading ? 'Enviando... ' : 'Enviar mensaje' }}
                </button>
              </div>
            </form>
          </div>
          </div>
		`,
  }).$mount(el);
})(document.getElementById('profile-contact'));
