const MultiCheck = {
  props: {
    value: {
      default: [],
      required: true,
    },
    taxonomy: {
      default: '',
      required: true,
    },
  },

  data: function () {
    return {
      checks: [],
      options: [],
      isFocused: false,
    };
  },

  watch: {
    value(value, old) {
      if (value === old) {
        return;
      }
      this.set();
    },
  },

  created() {
    const key = 'tax_' + this.taxonomy;
    const getFromLocal = new Promise((resolve, reject) => {
      resolve(brownies.local[key]);
    });

    getFromLocal
      .then((result) => {
        return (
          result ||
          axios.get('/terminos/tax/' + this.taxonomy + '/').then((response) => {
            brownies.local[key] = response.data;
            return response.data;
          })
        );
      })
      .then((data) => {
        this.options = data;
        this.set();
      });

    //click outside
    document.body.addEventListener('click', (e) => {
      //si pinchamos en el textarea, lo dejamos como venga
      if (e.target === this.$refs.tags) return;
      this.isFocused = e.target === this.$el || this.$el.contains(e.target);
    });
  },

  methods: {
    set() {
      this.checks = this.options.filter((option) =>
        this.value.includes(option._id),
      );
    },
    update() {
      this.$emit(
        'input',
        this.checks.map((item) => item._id),
      );
    },
  },

  template: `
      <div class="multi-check">
        <div class="uk-textarea" ref="tags" @click="isFocused = !isFocused">
            <span class="uk-badge" v-for="item in checks">
              {{ item.name }}
            </span>
            <span v-if="!checks.length" class="uk-badge">Cualquiera</span>
        </div>
        <div class="options" v-if="isFocused">
          <div v-for="item in options">
            <label>
              <input class="uk-checkbox" type="checkbox" :value="item" v-model="checks" @change="update">
              {{ item.name }}
            </label>
          </div>
        </div>
      </div>
	`,
};
