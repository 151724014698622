const DesktopNav = {
  data() {
    return {
      sectors: [],
      active: null,
      all: null,
    };
  },
  props: {
    publish: false,
    url: { default: null },
    base: { default: '/' },
  },
  created() {
    this.all = this.url || this.base;
    domestiko.sectors.length
      ? this.init()
      : domestiko.$on('sectors.loaded', this.init);
  },
  methods: {
    init() {
      this.sectors = domestiko.sectors;
      this.active = this.sectors[0];
    },
    activate(sector, $ev) {
      this.active = sector;
      if (!sector) return;
      this.$nextTick().then(() => {
        const li = $ev.target;
        const liRect = li.getBoundingClientRect();
        const activities = this.$el.getElementsByClassName('activities')[0];
        const activitiesRect = activities.getBoundingClientRect();

        /*if (activitiesRect.bottom < liRect.bottom) {
          console.log(activitiesRect.height);
          activities.style.top = activitiesRect.height / 2 + 'px';
        } else {
          activities.style.top = 0;
        }*/

        let top = 0;
        while (
          top + activitiesRect.bottom <
          li.getBoundingClientRect().bottom
        ) {
          top += 80;
        }
        activities.style.top = top + 'px';
      });
    },
  },
  template: `
    <div class="desktop-nav uk-navbar-dropdown">
      <div class="sectors">
        <ul class="uk-list">
          <li class="publish" v-if="publish">
            <a href="/directorio/pide-presupuesto/">Publica un empleo</a>
          </li>
          <li
            v-for="sector in sectors"
            @mouseenter="activate(sector, $event)"
            :class="{'uk-active': sector.slug === active?.slug}"
          >
            <a
              :href="base + sector.slug + '/'"
            >{{ sector.name }}</a>
          </li>
          <li 
            class="all"
            @mouseenter="activate(null, $event)"
          >
            <a :href="all">Ver todos los sectores</a>
          </li>
        </ul>
      </div>
      <div class="activities" v-if="active">
        <ul class="uk-list">
          <li v-for="activity in active.children">
            <a :href="base + activity.slug + '/'">{{ activity.name }}</a>
          </li>
          <li class="all">
            <a :href="base + active.slug + '/'">Ver todas las actividades</a>
          </li>
        </ul>
      </div>
    </div>
  `,
};

(function (el) {
  if (!el) return;

  new Vue({
    el: el,
    components: {
      'desktop-nav': DesktopNav,
    },
    methods: {},
    template: `
      <desktop-nav base="/" url="/directorio/#sectores" publish="true" />`,
  });
})(document.getElementById('desktop-nav-profiles'));

(function (el) {
  if (!el) return;

  new Vue({
    el: el,
    components: {
      'desktop-nav': DesktopNav,
    },
    methods: {},
    template: `
      <desktop-nav base="/empleo/" url="/empleo/#sectores" />`,
  });
})(document.getElementById('desktop-nav-jobs'));
