async function resizeImage(image) {
  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.addEventListener('load', () => resolve(img));
      img.addEventListener('error', (err) => reject(err));
      img.src = url;
    });
  };
  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader);
      reader.readAsDataURL(file);
    });
  };
  const scaleImage = (img, scale) => {
    const newWidth = img.naturalWidth * scale;
    const newHeight = img.naturalHeight * scale;
    let canvas = document.createElement('canvas');
    canvas['style']['width'] = `${newWidth}px`;
    canvas['style']['height'] = `${newHeight}px`;
    canvas['style']['border'] = '1px dashed #6c757d';
    canvas['style']['margin'] = '0 auto';
    canvas['style']['display'] = 'flex';
    canvas.width = newWidth;
    canvas.height = newHeight;
    canvas.getContext('2d').scale(1, 1);
    canvas.getContext('2d').drawImage(img, 0, 0, newWidth, newHeight);
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => resolve(blob), 'image/jpeg', 0.75);
    });
  };

  let b64str = await readFileAsDataURL(image).catch(console.error);
  let img = await loadImage(b64str).catch(console.error);

  const scale = Math.sqrt(2560000 / (img.naturalWidth * img.naturalHeight));

  console.log(
    img.naturalWidth,
    img.naturalHeight,
    scale,
    img.naturalWidth * scale,
    img.naturalHeight * scale,
  );
  if (scale > 1) {
    return image;
  }
  let result = await scaleImage(img, scale);
  return new File([result], image.name + '.jpg', { type: 'image/jpeg' });
}
