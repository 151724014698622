const TermSearch = {
  components: {
    TermBrowse,
  },

  props: {
    id: { default: false }, //input id
    css: { default: false }, //css classes
    value: { default: false }, //current value
    scope: { default: false }, //scope to query
    taxonomy: { default: false }, //taxonomy to query
    current: { default: false }, //current value
    checkbox: { default: false }, //clear after selected
    disabled: { default: false }, //is disabled
    placeholder: { default: '' }, //placeholder value to show
  },

  data: function () {
    return {
      query: '',
      open: false,
      item: false,
      results: false,
      selected: -1,
      loading: false,
      cancelTokenSource: false,
      mode: 'search',
    };
  },

  computed: {
    canBrowse() {
      return this.taxonomy === 'activities';
    },
  },

  watch: {
    //inicializamos js focus
    value(val, old) {
      if (val && old && val._id !== old._id) {
        this.select(val);
      } else if (!!val && !old.hasOwnProperty('name')) {
        this.select(val);
      }
    },
  },

  //inicializamos njk o js unfocus
  mounted() {
    if (this.value) {
      this.select(this.value);
    }
    if (this.current) {
      this.select(JSON.parse(this.current));
    }
  },

  methods: {
    blur: function ($event) {
      setTimeout(() => {
        this.$emit('blur', $event);
        //solo cerramos si no volvimos el focus al elemento
        this.open = document.activeElement === this.$el.querySelector('input');
      }, 100);
    },
    clear: function () {
      this.select(false);
      this.$el.querySelector('input').focus();
      this.$nextTick(() => (this.open = true));
    },
    search: function (value) {
      this.open = true;
      this.query = value;
      this.selected = -1;
      this.mode = 'search';

      if (value.length < 3) {
        this.results = false;
        return;
      }

      if (this.loading) {
        this.cancelTokenSource.cancel();
      }

      this.loading = true;
      this.cancelTokenSource = axios.CancelToken.source();

      let params = { q: value };
      if (this.scope) {
        params.s = this.scope;
      }

      //'/terminos/buscar/'
      let url = `/search/${this.taxonomy}/`;

      axios
        .get(url, {
          params: params,
          cancelToken: this.cancelTokenSource.token,
        })
        .then((response) => {
          this.loading = false;
          this.results = response.data;
        })
        .catch((e) => console.error('Error', e));
    },
    select: function (item) {
      this.item = item;
      this.results = [item];
      this.query = item.title;
      this.open = false;
      this.selected = -1;
      this.$emit('input', item);
      if (!!this.checkbox && item) this.clear();
    },
    selectPrev: function () {
      if (this.results && this.selected > 0) this.selected--;
    },
    selectNext: function () {
      if (this.results && this.selected < this.results.length - 1)
        this.selected++;
    },
    selectCurrent: function () {
      const selected = this.results[this.selected];
      if (this.item && this.selected < 0) {
        this.$emit('submit');
      } else if (selected) {
        this.select(selected);
        this.results = false;
        this.open = true;
      }
    },
  },

  template: `
    <div :class="{'term-search': true, [taxonomy]: true}">
      <span class="clear icon-close" v-show="query" @click.prevent="clear()"></span>
      <input
        :id="id"
        :class="css"
        :value="query"
        :disabled="disabled"
        :placeholder="placeholder"
        @focus="open = true"
        @blur="blur($event)"
        @input="search($event.target.value)"
        @keyup.enter="selectCurrent()"
        @keyup.up="selectPrev()" @keyup.down="selectNext()"
        autocomplete="off" type="text" class="uk-input"
      />
      <div class="results" :class="{ '-open': open }">
        <ul v-if="mode === 'search'">
          <li v-if="!query">
                        <span>
                          Escribe para empezar a buscar<br>
                          <a
                            v-if="canBrowse"
                            @mousedown.prevent="mode = 'browse'"
                          >o elige uno del listado</a>
                        </span>
          </li>
          <li v-if="loading">
            <span class="uk-text-muted">buscando...</span>
          </li>
          <li v-if="!loading && results.length === 0">
                        <span>
                          No se han encontrado resultados
                          <a
                            v-if="canBrowse"
                            @mousedown.prevent="mode = 'browse'"
                          >elige uno del listado</a>
                        </span>
          </li>
          <li
            v-if="!loading && results.length > 0"
            :class="{selected: i === selected}"
            v-for="(result, i) in results"
          >
            <a
              @mousedown.prevent="select(result)"
              @keyup.enter.prevent="select(result)"
            >
              {{ result.title }}
            </a>
          </li>
        </ul>
        <TermBrowse
          :taxonomy="taxonomy"
          @select="select($event)"
          v-if="canBrowse && mode === 'browse'"
        />
      </div>
    </div>
  `,
};
