(function (el) {
  if (!el) return;

  new Vue({
    el,
    data() {
      return {
        sectors: [],
        active: null,
        all: null,
      };
    },
    created() {
      this.all = this.url || this.base;
      domestiko.sectors.length
        ? this.init()
        : domestiko.$on('sectors.loaded', this.init);
    },
    methods: {
      init() {
        this.sectors = domestiko.sectors;
        this.active = this.sectors[0];
      },
      activate(sector) {
        this.active = sector;
      },
    },
  });
})(document.getElementById('offcanvas'));
