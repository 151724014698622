Vue.filter('initials', function (text) {
  const parts = text.split(' ');
  return (
    parts[0] +
    ' ' +
    parts
      .slice(1)
      .map((p) => p.substr(0, 1).toUpperCase() + '.')
      .join(' ')
  );
});

Vue.filter('truncate', function (text, words) {
  return text.split(' ').slice(0, words).join(' ') + '...';
});

Vue.filter('stripTags', function (text) {
  return text.replace(/(<([^>]+)>)/gi, ' ');
});

Vue.filter('autoLink', function (text) {
  return autoLink(text, {
    br: true,
    email: true,
    image: false,
    removeHTTP: true,
    linkAttr: {
      target: '_blank',
      rel: 'nofollow noopener',
    },
  });
});

Vue.filter('dateFormat', function (value, format = 'YYYY/MM/DD HH:mm:ss') {
  return dayjs(value).format(format);
});

Vue.filter('fileSize', function (size) {
  var sizes = ['B', 'kB', 'MB', 'GB', 'TB'];
  var i = Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
});

Vue.filter('idInArray', function (needle, haystack) {
  return haystack.map((item) => item._id).indexOf(needle._id) >= 0;
});
