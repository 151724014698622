const TurnstileWidget = {
  props: {
    sitekey: { type: String, required: true },
  },
  mounted() {
    if (window.turnstile === null || !window.turnstile) {
      const script = document.createElement('script');
      script.src =
        'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
    this.renderTurnstile();
  },
  methods: {
    renderTurnstile() {
      window.onloadTurnstileCallback = () => {
        if (!this.sitekey) {
          console.error('Turnstile key not defined');
          return;
        }
        window.turnstile?.render('#turnstile-widget', {
          sitekey: this.sitekey,
          callback: (response) => {
            this.$emit('verify', response);
          },
          'expired-callback': this.$emit('expire'),
          'error-callback': this.$emit('fail'),
        });
      };
    },
  },
  template: `
      <div id="turnstile-widget"></div>
	`,
};
