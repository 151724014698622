const vapid = {
  publicKey:
    'BNr8eDHd7IPxmqSidSLrhG7nBHre7hAJ87bxSxYbpehZ0eebLuyETC3GIV-vBeKLSqCdo-GCm8ENgXeOdScPM1g',
};

const allowNotifications =
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function registerPushNotifications() {
  return navigator.serviceWorker
    .register('/assets/js/service-worker.js')
    .then((registration) => {
      return registration.pushManager
        .getSubscription()
        .then(async (subscription) => {
          console.log('subscription', subscription);
          if (subscription) {
            return subscription;
          }
          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(vapid.publicKey),
          });
        });
    })
    .then((subscription) => {
      console.log('subscription', subscription.toJSON());
      return axios
        .post('/usuarios/suscribirse/', { subscription })
        .then((response) => response.data)
        .then((data) => {
          console.log(data);
          return true;
        });
    })
    .then((result) => {
      console.log('register', result);
      return Notification.requestPermission().then((result) => {
        console.log('request', result);
        if (result === 'granted') {
          new Notification('¡Bienvenido a las notificaciones de Doméstiko!', {
            body: 'A partir de ahora te enviaremos una notificación cada vez que algo ocurra en tu cuenta',
          });
        } else if (result === 'denied') {
          customAlert(
            'No se han podido activar las notificaciones',
            'Por favor desbloquea las notificaciones en tu navegador y vuelve a intentarlo.',
          );
        }
        return result === 'granted';
      });
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

domestiko.$on('user.loggedin', (user) => {
  if (!allowNotifications) {
    return;
  }

  if (window.location.search.includes('signup=true')) {
    return;
  }

  if (Notification.permission === 'granted') {
    return;
  }

  const now = new Date().getTime();
  const pushAgain = brownies.local.pushAgain || 0;
  if (pushAgain && now < pushAgain) {
    return;
  }

  const messages = [
    'Recibe las ofertas de trabajo de tu interés en cuanto se publiquen.', //perfiles
    'Recibe las respuestas a tus ofertas de trabajo en tiempo real.', //empleadores
    'Con las notificaciones de Doméstiko estarás al tanto de todo lo que te interese en tiempo real.', //genérico
  ];

  const message = user.jobs
    ? messages[1]
    : user.profile
    ? messages[0]
    : messages[2];

  customConfirm('¿Quieres recibir notificaciones?', message)
    .then((response) => {
      return registerPushNotifications();
    })
    .catch((response) => {
      brownies.local.pushAgain = now + 36 * 3600 * 1000;
    });
});
