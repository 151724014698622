var UserRegister = {
  data: function () {
    return {
      email: '',
      password: '',
      username: '',
      tos: '',
      error: '',
      loading: false,
    };
  },
  computed: {
    emailInput: function () {
      return this.$el.querySelector('[name=email]');
    },
    registerDisabled: function () {
      return (
        this.username === '' ||
        this.email === '' ||
        this.email.match(/[^a-zA-Z0-9@\.\-\_]/gi) ||
        this.password === '' ||
        this.password.length < 5 ||
        !this.tos
      );
    },
  },
  watch: {
    email() {
      this.emailInput.setCustomValidity('');
    },
  },
  methods: {
    register: function () {
      this.error = '';
      this.loading = true;

      axios
        .post('/usuarios/alta/', {
          email: this.email,
          password: this.password,
          username: this.username,
          type: this.type,
        })
        .then((res) => {
          this.error = '';
          console.log('Register OK!', res);
          domestiko.login(res.data);
          document.location = '/usuarios/panel/?signup=true';
        })
        .catch((err) => {
          console.log('Register KO!', err.response);
          if (err.response) {
            this.error = err.response.data.message;
          }
          if (err.response.data.code === 'INVALID_EMAIL') {
            this.emailInput.setCustomValidity('Correo electrónico no válido');
            this.emailInput.reportValidity();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  template: `
      <form class="uk-form-stacked user-register" :class="{loading: loading}">
      <div class="uk-margin-top">
        <label>Nombre</label>
        <div class="uk-form-controls">
          <input type="text" name="name" class="uk-input" autocomplete="name" v-model="username">
        </div>
      </div>
      <div class="uk-margin-top">
        <label>Correo electrónico</label>
        <div class="uk-form-controls">
          <input type="email" name="email" class="uk-input" autocomplete="email" v-model="email">
        </div>
      </div>
      <div class="uk-margin-top">
        <label>Contraseña</label>
        <div class="uk-form-controls">
          <input type="password" name="password" autocomplete="current-password" class="uk-input" v-model="password">
        </div>
      </div>
      <div class="uk-margin-top">
        <input type="checkbox" class="uk-checkbox" v-model="tos" />
        He leído y acepto las 
        <a href="/info/nota-legal/" target="_blank">Condiciones de servicio</a> 
        y la 
        <a href="/info/politica-de-privacidad/" target="_blank">Política de Privacidad de Doméstiko</a>.
      </div>
      <div class="uk-margin-top">
        <button 
	        class="uk-button uk-button-primary"
	        :class="{'uk-disabled': registerDisabled}"
	        @click.prevent="register()" 
	        :disabled="registerDisabled"
        >
          Crear cuenta
        </button>
      </div>
      <div class="uk-margin-top">
        <a href="#login" uk-toggle>Tienes una cuenta ¿quieres iniciar sesión?</a>
      </div>
      <template v-if="error">
        <div class="uk-alert uk-alert-warning">
          <span uk-icon="icon:warning"></span>{{ error }}
        </div>
      </template>
      <div class="uk-margin-top">
        <p class="legal -tos">
          Responsable del Tratamiento: Luis G. Valdivieso; finalidad del tratamiento: prestación del servicio; legitimación del tratamiento: consentimiento del interesado; destinatarios: otros usuarios del sitio web, colaboradores en la prestación del servicio y autoridades cuando se nos requiera legalmente; derechos: acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la información adicional.
          Derechos: correo a <a href="mailto:privacidad@domestiko.com">privacidad@domestiko.com</a>.
          Más información en la <a href="/info/politica-de-privacidad/" target="_blank">política de privacidad</a> y <a href="/info/politica-de-cookies/" target="_blank">cookies</a>.
        </p>
      </div>
      </form>
	`,
};

(function () {
  ['block-register', 'offcanvas-register'].map((el) => {
    new Vue({
      el: document.getElementById(el),
      components: { 'user-register': UserRegister },
    });
  });
})();
