(function (el) {
  if (!el) {
    return;
  }

  new Vue({
    components: {
      avatar: Avatar,
    },
    data: {
      user: false,
    },
    created() {
      domestiko.$on('user.loggedin', () => {
        this.user = domestiko.user;
      });
      domestiko.$on('user.loggedout', () => {
        this.user = false;
      });
    },
    methods: {
      login: function () {
        domestiko.$emit('form.login');
      },
      register: function () {
        domestiko.$emit('form.register');
      },
      logout: function () {
        domestiko.logout();
      },
    },
    template: `
          <ul class="uk-navbar-nav uk-helper-nav user-nav">
	          <li>
	            <a href="#search-bar" class="uk-icon-link search-trigger" alt="Buscar" aria-label="Buscar">
	              <i class="icon-search"></i>
	            </a>
	          </li>
	          <li v-if="!user" class="uk-visible@l uk-margin-small-right">
	            <a href="/usuarios/login/" @click.prevent="login()" class="uk-button uk-button-ghost">Acceder</a>
	          </li>
	          <li v-if="!user" class="uk-hidden@l">
	            <a href="/usuarios/login/" @click.prevent="login()" class="uk-navbar-item uk-icon-link" alt="Acceder" aria-label="Acceder">
	              <i class="icon-user"></i>
	            </a>
	          </li>
	          <li v-if="!user" class="uk-visible@l">
	            <a href="/alta/" @click.prevent="register()"
	               class="uk-button uk-button-primary">Regístrarse</a>
	          </li>
	          <li v-if="user">
	            <button class="uk-button uk-button-link uk-position-relative">
	              <avatar :name="user.username" :image="user.avatar"/>
	              <span class="uk-badge uk-user-badge" v-if="user.messages > 0 || user.notifications > 0">
	                {{user.notifications || 0 + user.messages || 0 }}
	              </span>
	            </button>
	            <div uk-dropdown>
	              <ul class="uk-nav uk-dropdown-nav">
	                <li class="uk-nav-header">{{ user.username }}</li>
	                <li><a href="/usuarios/panel/">Mi cuenta</a></li>
	                <li class="uk-nav-divider"></li>
	                <li><a href="/usuarios/salir/" @click.prevent="logout()">Cerrar sesión</a></li>
	              </ul>
	            </div>
	          </li>
          </ul>
		`,
  }).$mount(el);
})(document.getElementById('user-nav-block'));
