const Avatar = {
  props: {
    name: { default: '' },
    image: { default: false },
  },

  computed: {
    initials() {
      return this.name.replace(/\W/, '').substr(0, 1);
    },
  },

  template: `
      <div class="avatar">
	      <img v-if="image" :src="image" :alt="name"/>
	      <div v-if="!image" class="-default">
	        <span>{{ initials }}</span>
	      </div>
      </div>
	`,
};
