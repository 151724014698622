(function (el) {
  if (!el) return;
  new Vue(UserPassword).$mount(el);
})(document.getElementById('block-user-password'));

(function (el) {
  if (!el) return;
  const c = new Vue(UserPassword).$mount(el);
  c.status = 'automatic';
})(document.getElementById('block-automatic-password'));
