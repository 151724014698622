(function (el) {
  if (!el) return;

  new Vue({
    el: el,
    data: {
      regional: '',
      actividad: '',
      local: false,
      tipo: urlQueryParams('type') || '',
      disponibilidad: [],
      idiomas: [],
      actualizado: '',
      opciones: {
        disponibilidad: [],
        idiomas: [],
      },
      isMounted: false,
    },
    components: {
      'multi-check': MultiCheck,
      'term-search': TermSearch,
    },

    created() {
      this.isMounted = true;
      //populate normal fields
      const url = new URL(window.location.href);
      this.local = url.searchParams.get('local');
      this.actualizado = url.searchParams.get('updated') || '';
      this.idiomas = (url.searchParams.get('languages') || '').split(',');
      this.disponibilidad = (url.searchParams.get('availability') || '')
        .split(',')
        .map((a) => 'availability.' + a);
    },
    methods: {
      search: function () {
        let url = [];
        if (this.actividad) url.push(this.actividad.slug);
        if (this.regional) url.push(this.regional.slug);
        if (url.length === 0) {
          UIkit.modal.alert('Por favor introduce algún criterio de búsqueda');
          return;
        }
        url = '/' + url.join('/') + '/';

        const params = new URLSearchParams();
        if (this.local) {
          params.append('local', this.local);
        }
        if (this.tipo) {
          params.append('type', this.tipo);
        }
        if (this.disponibilidad.length) {
          params.append(
            'availability',
            this.disponibilidad.map((a) => a.split('.')[1]),
          );
        }
        if (this.idiomas.length) {
          params.append('languages', this.idiomas);
        }
        if (this.actualizado) {
          params.append('updated', this.actualizado);
        }

        if (params.size) {
          url += '?' + params.toString();
        }

        //console.log('profiles.search', url);
        location = url;
      },
      clear: function () {
        this.regional = '';
        this.actividad = '';
      },
      reset: function () {
        this.tipo = '';
        this.local = false;
        this.idiomas = [];
        this.disponibilidad = [];
        this.actualizado = '';
      },
    },
  });
})(document.getElementsByClassName('profiles-search-form')[0]);
