(function (el) {
  if (!el) return;

  new Vue({
    el: el,
    data: {
      regional: '',
      actividad: '',
      query: '',
    },
    components: {
      'term-search': TermSearch,
    },
    methods: {
      search: function (section) {
        const sections = {
          jobs: 'empleo',
          help: 'ayuda',
          blog: 'blog',
          profiles: false,
        };
        let parts = [];
        if (sections[section]) parts.push(sections[section]);

        if (this.actividad) parts.push(this.actividad.slug);
        if (this.regional) parts.push(this.regional.slug);

        if (this.query) parts.push('buscar/?q=' + this.query);
        else if (parts.length > 0) parts.push('');

        if (parts.length === 0) {
          return;
        }

        const url = ['', ...parts].join('/');
        location = url;
      },
      clear: function () {
        this.regional = '';
        this.actividad = '';
      },
    },
  });
})(document.getElementsByClassName('search-bar')[0]);
